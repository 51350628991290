import React from 'react';
import Loader from './Loader';
import { Result, Icon, Button } from 'antd';

function Loading(props) {
  if (props.error) {
    return (
      <Result
        icon={<Icon type="download" />}
        title="Se deben descargar actualizaciones necesarias"
        extra={<Button
          type="primary"
          icon="download"
          onClick={() => window.location.reload(true)}
          size="large"
        >
          Descargar
        </Button>}
      />
    );
  } else if (props.pastDelay) {
    return <div className="loader-container"> <Loader /> </div>;
  } else {
    return null; // Avoiding Flash Of Loading Component (<200ms)
  }
}

export default Loading;